import React from 'react';
import SideBar from './headsidebar/SideBar';
import Header from './headsidebar/Header';
import { getAccessTokenInStore, getRoleInStore } from '../utils/apiUtils';
import { initGA, logPageView } from '../analytics';

const HeaderSidebarContainer = () => {
	const token = getAccessTokenInStore();
	const role = getRoleInStore();
	const [sidebarOpen, setSidebarOpen] = React.useState(false);
	const sideNavRef = React.useRef(null);
	const isAuthenticated = () => {
		const authcheck = token && token !== undefined;
		return authcheck;
	};
	const [notificationStatus, setNotificationStatus] = React.useState(false);

	React.useEffect(() => {
		initGA();
		logPageView();
		// Add event listener to the document object
		document.addEventListener('mousedown', handleClickOutside);

		// Remove event listener when the component unmounts
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [sideNavRef]);

	function handleClickOutside(event) {
		if (
			!(
				event.target.id === 'sidebarCollapse' || event.target.id === 'hamburger'
			) &&
			sideNavRef.current &&
			!sideNavRef.current.contains(event.target)
		) {
			setSidebarOpen(false);
		}
	}

	const handleViewSidebar = React.useCallback(() => {
		setSidebarOpen((prevState) => !prevState);
	}, []);

	return (
		<div className={isAuthenticated() ? '' : 'hideElements'}>
			<Header
				onClick={handleViewSidebar}
				notificationStatus={notificationStatus}
				userRole={role}
				className='topbar'
			/>
			<div ref={sideNavRef}>
				<SideBar
					isOpen={sidebarOpen}
					toggleSidebar={handleViewSidebar}
					notificationStatus={notificationStatus}
				/>
			</div>
		</div>
	);
};

export default HeaderSidebarContainer;
