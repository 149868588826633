import React from 'react';
import intl from '../../locale/en-US.json';
import { Categories, logEvent, Actions } from '../../analytics';
import AllImages from '../../assets/images/images';
import { Image } from 'semantic-ui-react';
import {
	// getPriorityUserIdInStore,
	// getPriorityVerificationStatus,
	// getPriorityNotificationStatusInStore,
	getNotificationStatusInStore,
} from '../../utils/apiUtils';

const AdminInfo = ({ history, adminInfo }) => {
	// console.log('admin info ', adminInfo)
	const [data, setData] = React.useState([]);

	React.useEffect(() => {
		setData([]);

		// const priorityId = getPriorityUserIdInStore();
		// const priorityVerification =
		// 	getPriorityVerificationStatus() === 'true' ? true : false;
		// let isPriorityContactAdded =
		// 	(priorityId && priorityId !== undefined && priorityId !== 'undefined'
		// 		? true
		// 		: false) && priorityVerification;

		// let status = true;
		// if (
		// 	getPriorityUserIdInStore() &&
		// 	(getPriorityVerificationStatus() === 'true' ? true : false)
		// ) {
		// 	status = getPriorityNotificationStatusInStore();
		// } else {
		// 	status = getNotificationStatusInStore();
		// }

		
		const adminData = adminInfo.find((obj) => obj.role === 'SchoolAdmin');
		if(adminData) {
			setData((prev) => [
				...prev,
				{
					email: adminData.email,
					phone_no: adminData.phone_no,
					role: 'Admin',
					notificationStatus: getNotificationStatusInStore(),
				},
			]);		

			// if (isPriorityContactAdded) {
			// 	const priorityData = adminInfo.find(
			// 		(obj) => obj.role === 'PriorityContact'
			// 	);
			// 	setData((prev) => [
			// 		...prev,
			// 		{
			// 			email: priorityData.email,
			// 			phone_no: priorityData.phone_no,
			// 			role: 'Alternate',
			// 			notificationStatus: getPriorityNotificationStatusInStore(),
			// 		},
			// 	]);
			// }
	}
	}, [
		//getPriorityVerificationStatus()
	]);
	
	// const disableAdminNotificationClass = (role) => {
	// 	const priorityId = getPriorityUserIdInStore();
	// 	const priorityVerification =
	// 		getPriorityVerificationStatus() === 'true' ? true : false;
	// 	let isPriorityContactAdded =
	// 		(priorityId && priorityId !== undefined && priorityId !== 'undefined'
	// 			? true
	// 			: false) && priorityVerification;
	// 	if(isPriorityContactAdded && role === "Admin"){
	// 		return "disabledNotificationStatus"
	// 	}  
	// }
	return (
		<div
			className='card_headertitle  mt-3 hover-pointer'
			style={{padding: '0.65rem 1.25rem'}}
			onClick={() => {
				history.push('/settings');
				logEvent({
					category: Categories.accountSettings,
					action: Actions.accountSettings_btn,
					label: 'dashboard_card',
				});
			}}
		>
			<div className='row cursor'>
				<div className='col-lg-2 col-md-4 col-sm-4 d-flex justify-content-center align-items-center logo-margin'>
					<div className='school_logo'>
						<center>
							<Image
								src={AllImages['adminContactIcon']}
								className='img-logo'
								style={{ width: '120px' }}
								alt='Admin contact icon'
							/>
						</center>
					</div>
				</div>
				<div className='d-flex flex-column col-lg-10 col-md-8 col-sm-8 logo-margin'>
					<div className='row d-flex'>
						{data &&
							data.map((data, index) => {
								//const roleclass = disableAdminNotificationClass(data.role);
								const roleclass = ""
								return (
									
									<React.Fragment key={index}>
										
										<div className='mb-4 mt-4'>
										<h4 className='card_head  CardTitle text-uppercase text-center-school text-center'>
											<strong className={`ml-2 text-center ${roleclass}`}>{data && data.role ==="Alternate" ? 'Alternate Contact': 'Admin Contact'}</strong>
										</h4>
										</div>
										
										<div className='col-lg-4 col-md-4 mb-4 col-sm-12 text-center'  >
											<p className={`${roleclass} card_head  CardTitle text-uppercase`}>
												{intl.emailAddress}
											</p>

											<p
												className={`${roleclass} card_head  CardTitle text-uppercase`}
												title={data && data.email}
											>
												<strong className={`${roleclass} word-break`} >
													<strong>{data && data.email}</strong>
												</strong>
											</p>
										</div>

										<div className={`col-lg-4 col-md-4 mb-4 col-sm-12 text-center 
											`}>
											<p className={`${roleclass} card_head  CardTitle text-uppercase`}>
												{intl.phoneNo}
											</p>

											<p
												className={`${roleclass} card_head  CardTitle text-uppercase long_timezone`}
												title={data && data.phone_no}
											>
												<strong className='word-break'>
													<strong>{data && data.phone_no}</strong>
												</strong>
											</p>
										</div>

										<div className='col-lg-4 col-md-4 mb-4 col-sm-12   text-center '>
											<p className={`card_head  CardTitle text-uppercase
												`}>
												{intl.receiveNotification}
											</p>

											<p className='text-center card_head  CardTitle text-uppercase long_timezone '>
												<strong className='word-break'>
													<strong>
														<strong
															className={
																data && data.notificationStatus === 'false'
																	? `notificationOff`
																	: `notificationOn`
															}
														>
															{data && data.notificationStatus === 'true'
																? 'On'
																: data.notificationStatus === 'false'
																? 'Off'
																: 'On'}
														</strong>{' '}
													</strong>
												</strong>
											</p>
										</div>
									</React.Fragment>
								);
							})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminInfo;
