import { useEffect, useState } from 'react';
import {FaTimes, FaTimesCircle} from 'react-icons/fa'
import React from 'react';
import { DateRange } from 'react-date-range';
import {tz} from 'moment-timezone';
import moment from 'moment';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import Strings from '../../locale/en-US.json';
import apiInstance from '../../api';
import { ToastContainer } from 'react-toastify';
import {
	deleteEtag,
	getListInStore,
	getEtag,
	setEtag,
	setListInStore,
	PANEL_ETAG_KEY,
	PANEL_LIST_KEY,
	getPageAndCountInStore,
	PANEL_PAGECOUNT_KEY,
	setPageAndCountInStore,
} from '../../utils/apiUtils';
import 'react-toastify/dist/ReactToastify.css';
import { Pagination } from 'semantic-ui-react';
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { maxLength } from '../../utils/apiUtils';
import { errorFunction } from '../../utils/errors';
import LogoutModal from '../Modal/LogoutModal';
import intl from '../../locale/en-US.json';
import useInputFocus from '../../utils/customHooks/useInputFocus';
import { logEvent,Categories,Actions } from "../../analytics";
import { startPolling, stopPolling, handleVisibilityChange } from '../../utils/polling';
import {NotificationIconTooltip, NotificationPopup} from './NotificationPopup';

const LIMIT_OFFSET = 15;

const PanelAlerts = () => {
	const divRef = React.useRef();
	const [panelAlertsList, setPanelAlertsList] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [dateValueTemp, setDateValueTemp] = useState(['', '']);
	const [dateValue, setDateValue] = useState(['', '']);
	const [timezone, setTimezone] = useState('');
	const [loading, setLoading] = useState(true);
	const [errorMsg, setErrorMsg] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [showModal2, setShowModal2] = useState(false);
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [count, setCount] = useState(0);
	const [disablePagination, setDisablePagination] = useState(false);
	const [selectedRange, setSelectedRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		}
	]);
	const [selectedRangeP, setSelectedRangeP] = useState([{
		startDate: null,
		endDate: null,
		key: 'selection',
	}]);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const timeoutIdRef = React.useRef(null);
	const isAnyInputFocused = useInputFocus();
	const pollingIntervalRef = React.useRef(null);
	
	useEffect(() => {
		deleteEtag(PANEL_ETAG_KEY);
		setLoading(true);
		setCurrentPage(1);
		fetchData();
		return () => {
			setLoading(false);
		};
	}, [searchTerm, dateValue]);

	const handlePageChange = (event, { activePage }) => {
		setLoading(true);
		setDisablePagination(true);
		setCurrentPage(activePage);
	};
  
	useEffect(() => {
		deleteEtag(PANEL_ETAG_KEY);
		setLoading(true);
		fetchData();
	}, [currentPage]);
  
	const visibilityChangeHandler = () => handleVisibilityChange(timeoutIdRef, fetchData, pollingIntervalRef);

	useEffect(() => {
		const storedPanelList = getListInStore(PANEL_LIST_KEY);
		const pageCountList = getPageAndCountInStore(PANEL_PAGECOUNT_KEY)
		if (storedPanelList && pageCountList) {
			setPanelAlertsList(JSON.parse(storedPanelList));
			const data = JSON.parse(pageCountList);
			setTotalPages(data.totalPages);
			setCount(data.count);
		}
		document.addEventListener("visibilitychange", visibilityChangeHandler);
		return () => {
		stopPolling(pollingIntervalRef);
		if (timeoutIdRef.current) {
			clearTimeout(timeoutIdRef.current);
		}
		document.removeEventListener("visibilitychange", visibilityChangeHandler);
		};
	}, [currentPage, searchTerm, dateValue]);
	
	useEffect(() => {
		const handleKeyDown = (event) => {
		  if (event.ctrlKey && event.key === 'z' && !isAnyInputFocused) {
			event.preventDefault(); // Prevent default undo behavior when no input is focused
		  }
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => {
		  document.removeEventListener('keydown', handleKeyDown);
		};
	  }, [isAnyInputFocused]);
	
	React.useEffect(() => {
		function handleClickOutside(event) {
			if (
				!(event.target.id === 'AddStudent') && !(event.target.id === 'btnApply') && !(event.target.id === 'btnClear') && 
				divRef.current &&
				!divRef.current.contains(event.target)
			) {
				//setShowDatePicker(false);
				if(selectedRangeP[0].startDate == null){
					const currentDate = new Date();
					setSelectedRange([{
						startDate: currentDate,
						endDate: currentDate,
						key: 'selection',
					   }]);
					 
					   //prevSelectedRangeRef.current = selectedRange;
					   setStartDate('');
					   setEndDate('');
					   if (!dateValue.every(date => date === '')) {
					    	setDateValue(['',''])
					   }
					   setDateValueTemp(['', '']);
				}
				else {
					setSelectedRange(selectedRangeP);
					//selectedRange = prevSelectedRangeRef.current;
					const date = new moment(selectedRange[0].startDate);
					setStartDate(date.format('MMM DD YYYY'));
					const dateE = new moment(selectedRange[0].endDate);
					setEndDate(dateE.format('MMM DD YYYY'));
				}
			 	setShowDatePicker(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [divRef,selectedRangeP]);


	const fetchData = async () => {
		try {
			const lowercasePathname = window.location.pathname.toLowerCase();
			if (!lowercasePathname.includes('notifications')) {
			  stopPolling(pollingIntervalRef);
			  return;
			}
			let url = '/api/v1/devices/alerts';
			let queryString = '';
			if (searchTerm.length > 0) {
				queryString += `search=${searchTerm}`;
			}
			if (dateValue[0] !== undefined && dateValue[0].toString().length > 0) {
				const startD = moment(dateValue[0].toString(), "ddd MMM D YYYY HH:mm:ss [GMT]ZZ");
				const startDateTime  = startD.utc().format();
				const endD = moment(dateValue[1].toString(), "ddd MMM D YYYY HH:mm:ss [GMT]ZZ");
				const endDateTime  = endD.utc().format();
				const timeZoneMoment = tz.guess();
				if (queryString.length > 0) {
					queryString += '&';
				}
				queryString += `startDateTime=${startDateTime}&endDateTime=${endDateTime}&timezone${timeZoneMoment}`;
			}
			if (queryString.length > 0) {
				url = `/api/v1/devices/alerts?${queryString}`;
			}
			const headers = {};
			const storedEtag = getEtag(PANEL_ETAG_KEY);
			if (storedEtag) {
				headers['If-None-Match'] = storedEtag;
			}
			stopPolling(pollingIntervalRef);
			const res = await apiInstance.get(url, {
				headers,
				params: {
					page: currentPage,
					limit: LIMIT_OFFSET,
				},
			});
			startPolling(fetchData, pollingIntervalRef);
			if (res && res.data && res.data.data) {
				setLoading(false);
 				setPanelAlertsList(res.data.data.alerts);
				setEtag(PANEL_ETAG_KEY, res.headers.etag);
				setCount(res.data.data.totalAlerts);
				setTotalPages(res.data.data.totalPages); // Set the total number of pages
				setListInStore(PANEL_LIST_KEY, JSON.stringify(res.data.data.alerts));
				const pageAndCount = {count:res.data.data.totalAlerts,
					totalPages:res.data.data.totalPages}
				setPageAndCountInStore(PANEL_PAGECOUNT_KEY, JSON.stringify(pageAndCount));
        		setDisablePagination(false);
			}
		} catch (error) {
			startPolling(fetchData, pollingIntervalRef);
			if (error.response && error.response.status === 304) {
				setLoading(false);
				setErrorMsg("");
				return;
			}
			const result = errorFunction(error);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				setErrorMsg("Error in fetching alerts");
			}
		} 
	};

	useEffect(() => {
		const localTimezone = tz.guess();
        setTimezone(localTimezone);
		deleteEtag(PANEL_ETAG_KEY);
	}, []);
	
    
	const handleClearRange = ()=>{
		const currentDate = new Date(); // Get the current date
    	setSelectedRange([{
         startDate: currentDate,
         endDate: currentDate,
         key: 'selection',
        }]);
		setSelectedRangeP([{
			startDate: null,
			endDate: null,
			key: 'selection',
		   }]);
		setStartDate('');
		setEndDate('');
		setDateValue(['',''])
		setDateValueTemp(['', '']);
		setCurrentPage(1);
		setLoading(true);
	}


	const handleApply  = ()=>{
		stopPolling(pollingIntervalRef);
		setShowDatePicker(false);
		setDateValue([dateValueTemp[0], dateValueTemp[1]]);
		setSelectedRangeP(selectedRange);
		setCurrentPage(1);
		setTotalPages(1);
		setLoading(true);
		startPolling(fetchData, pollingIntervalRef);
	}

	const handleSelect = (ranges) => {
		const selectedStartDate = ranges.selection.startDate;
		const selectedEndDate = ranges.selection.endDate;
		const today = new Date();

 		if (selectedStartDate > today) {
			ranges.selection.startDate = today;
		}

 		if (selectedEndDate > today) {
			ranges.selection.endDate = today;
		}

		setSelectedRange([ranges.selection]);
 		const date = new moment(ranges.selection.startDate);
		setStartDate(date.format('MMM DD YYYY'));
		const dateE = new moment(ranges.selection.endDate);
		setEndDate(dateE.format('MMM DD YYYY'));
		setDateValueTemp([date.startOf('day'),dateE.endOf('day')]);
	};

	const onSearchClear = async () => {
		setSearchTerm('');
		setSearchText('');
	}


	return (
		<div>
				<Breadcrumb
					links={[{ link: '/panelnotifications', title: Strings.panelAlertList }]}
					
				/>

				<div className='container-fluid'>
					<div className='row page-titles'>
						<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
							<div
								className='card CardNoBorder'
								id='PersonForm'
								style={{ border: '3px solid red', 
								// minWidth: '25em'
							 }}
							>
								<div
									className='card-header card_headertitle'
								>
									<div className='card-title card_head'>
										<div className='row d-flex align-items-center'>
											<div className='col-xl-2 col-lg-2 col-md-12 col-sm-12'>
												<h5 className='card_head CardTitle text-uppercase md-font component-heading  '>
													{Strings.panelAlert}
												</h5>
											</div>
											
											<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex'>
												<input
													type='search'
													id='searchName'
													name='searchName'
													placeholder={intl.searchButton}
													className='w-100 search-input'
													style={searchText.length > 1 ? {borderRight: '0px solid red', borderTopRightRadius: '0em', borderBottomRightRadius:'0em'} : null}
													value={searchText}
													onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            setSearchTerm(searchText)
															setCurrentPage(1)
                                                        }
                                                    }}
													onChange={(e) => {maxLength(e.target.value, 255); setSearchText(e.target.value);
													   setErrorMsg('')}}
												/>

												{searchText.length > 1 ? <span className='cancel-container' onClick={() => {(searchText.length > 1 && onSearchClear());
										 }}>
													<FaTimes id="btnClear" className='cancel-icon' />
												</span> : null }
											</div>

											
											<div
												className='col-xl-1 col-lg-2 col-md-12 col-sm-12 col-12 panel-alert-padding'>
												<div 
												style={{position: 'relative', height:'3em'}} 
												className=''>
													<input
														onClick={() => {
															setSearchTerm(searchText)
															setCurrentPage(1);
															logEvent({category:Categories.alert,action:Actions.alert_search_btn});
														}}
														type='submit'
														className='btn ActionButton PersonBtnadd PersonBtnloader button-fixed search_button'
														value={Strings.searchButton}
														id='PersonBtn'
													/>
												</div>
											</div>
											<div
												className=' col-xl-3 col-lg-5 col-md-12 col-sm-12 col-12 date-picl-div strip_top_margin'
												style={{ position: 'relative', height: '3em'}}
											>
												<div
													className=' mr-3 date_picker  '
													style={{ position: 'absolute',zIndex: '3' }}
												>
													
													{
														showDatePicker ? (
															<div ref={divRef} id='AddStudent' >
																<DateRange
																	editableDateInputs={true}
																	moveRangeOnFirstSelection={false}
																	retainEndDateOnFirstSelection={true}
																	ranges={selectedRange}
																	onChange={handleSelect}
																	showSelectionPreview={true}
																/>
															</div>
													) : (startDate === '' && endDate === '' ) ? (
														<div
															className='rdrCalendarWrapper '
															// id='AddStudent'
															onClick={() => setShowDatePicker(!showDatePicker)}
														>
															<div className='rdrDateDisplayWrapper' style={{  width:'240px'}}>
																<div
																	className='rdrDateDisplay'
																	style={{ color: 'rgb(61,145,255' }}
																>
																	<span className='rdrDateInput rdrDateDisplayItem ' style={{width:'50px'}}>
																		<input
																			placeholder='Start Date'
																			value='All'
																			className='date-input'
																			onChange={() => {}}
																			style={{width:'100px'}}
																		/>
																	</span>			

																</div>
															</div>
														</div>) : <div
															className='rdrCalendarWrapper '
															// id='AddStudent'
															onClick={() => setShowDatePicker(!showDatePicker)}
														>
															<div className='rdrDateDisplayWrapper' style={{  width:'240px'}}>
																<div
																	className='rdrDateDisplay'
																	style={{ color: 'rgb(61,145,255' }}
																>
																	<span className='rdrDateInput rdrDateDisplayItem ' style={{width:'50px'}}>
																		<input
																			placeholder='Start Date'
																			value={startDate}
																			className='date-input'
																			onChange={() => {}}
																			style={{width:'100px'}}
																		/>
																	</span>
																	<span className='rdrDateInput rdrDateDisplayItem' style={{width:'50px'}}>
																		<input
																			placeholder='End Date'
																			value={endDate}
																			className='date-input'
																			onChange={() => {}}
																			style={{width:'100px'}}
																		/>
																	</span>
																</div>
															</div>
															
														</div>
														
														}
														{ !showDatePicker && (startDate !== '' && endDate !== '' )? 
														<FaTimesCircle id = "btnClear" 
															style={{cursor:'pointer'}}
															onClick={() => {handleClearRange();}} className='cancel-button'>
																
															</FaTimesCircle>
															 : null
														}
														{showDatePicker ? 
														<button 
														className='btn ActionButton PersonBtnadd PersonBtnloader rdrDateInput rdrDateDisplayItem' style={{width:'242px'}}
															id='btnApply'
															onClick={() => {handleApply();	logEvent({category:Categories.alert,action:Actions.alert_apply_btn});
														}}
															>{Strings.apply}
														</button>: null
														}
												</div>
											</div>
										</div>
									</div>
								</div>
								{errorMsg && (
							<div className='btn_error'>
								{errorMsg}
							</div>
						)}
								<div className='card-body DataTable PersonGrid'>
									{loading && panelAlertsList ? (
										<div className="d-flex justify-content-center align-items-center">
											<Spinner animation="border" variant="secondary" />
										</div>
									) : (
										<div className='table-responsive'>
											<table className='table table-bordered'>
												<thead>
													<tr>
														<th scope='col' style={{ width: "15%" }}>{Strings.ownerPanel}</th>
														<th scope='col' style={{ width: "15%" }}>{Strings.eventDateTimePanel}</th>
														<th scope='col' style={{ width: "35%" }} >{Strings.recipientsPanel}</th>
														<th scope='col' style={{ width: "15%" }}>{Strings.devicePanel}</th>
													</tr>
												</thead>
												<tbody>
													{panelAlertsList && panelAlertsList.length > 0 ? (
														panelAlertsList.map((panelAlert, index) => {
															return (
																<tr key={index} >
																	<td>
																		<p>{panelAlert.owner_name}</p>
																		<p style={{ fontSize: '0.66em',color: 'grey', marginTop:'-10px'}}>{panelAlert.mac_address}</p>
																	</td>
																	<td>
																		<p>{moment(panelAlert.timestamp)
																			.tz(timezone)
																			.format('DD MMM YYYY, HH:mm:ss')}</p>
																			<p style={{ fontSize: '0.66em', marginTop:'-10px'}}>RSSI {panelAlert.rssi}{' '} TxPower {panelAlert.ibeacon_tx_power}</p>
																	</td>
																	<td>
																		<div className="d-flex">
																		<div>
																			{panelAlert.recipients
																				? panelAlert.recipients.slice(0,2).map(
																						(recipient, index) => (
																							<div key={index}>
																								<div className="d-flex">
																									<p className='long_recipient' title={recipient.name} style={{ paddingRight: '10px',width: '170px' }}>{`${recipient.name}`}</p>
																									<div style={{width: '115px'}}>
																										<NotificationIconTooltip recipient={recipient} />
																									</div>
 																								</div>
																							</div>
																						)
																				)
																				: null} 
																		</div>
												
																		{panelAlert.recipients && panelAlert.recipients.length > 2 &&
																				<OverlayTrigger
																					placement="right"
																					trigger={['click']}
																					rootClose={true}
																					overlay={
																					<Tooltip id='notifications_tooltip'>
																						<div className='tooltip_div'>
																							<h5 className='tooltip_heading' style={{paddingTop:'15px', paddingBottom:'5px'}}>Reported To</h5>
																							<hr/>
																							<div className='notifications_tooltip_scroll'>
																									{
																										panelAlert.recipients.slice(2).map((recipient, i) => {
																											return (
																												<React.Fragment key={i}>
																													<NotificationPopup recipient={recipient} />
																													{i === panelAlert.recipients.slice(2).length - 1 ? null : <hr/>}
																												</React.Fragment>
																											)
																										})
																									}
																								{/* <hr/> */}
																							</div>
																						</div>
																					</Tooltip>
																					}
																				>
																					<div className='more_notification_btn' style={{marginLeft: '40px' }}>
																						{panelAlert.recipients && 
																						panelAlert.recipients.slice(2).length} more
																					</div>
																			</OverlayTrigger>	
																		}
																	</div>
																	</td>
																	<td><p>{panelAlert.device_type}</p>
																		<p style={{ fontSize: '0.8em', color: 'grey', marginTop:'-10px'}}>{panelAlert.device_number}</p>
																	</td>
																</tr>
															);
														})
													) : (
														<tr>
															<td colSpan='6' style={{ textAlign: 'center' }}>
																{intl.noRecordFound}
															</td>
														</tr>
													)}
												</tbody>
											</table>
											{ (panelAlertsList && panelAlertsList.length) > 0 ?
												<div className='count_div'>
													<Pagination
														boundaryRange={1}
														ellipsisItem="..."
														firstItem={null}
														lastItem={null}
														activePage={currentPage}
														totalPages={totalPages}
														siblingRange={1}
														disabled={disablePagination}
														onPageChange={handlePageChange}
														className={totalPages === 1 ? "paginationbutton" : null}
													/>
													<div>Total: {count}</div>
												</div>
												: ''
											}
											<ToastContainer />
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			{showModal2 ? <LogoutModal /> : null}
		</div>
	);
};

export default PanelAlerts;