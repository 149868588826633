import React from "react";
import Modal from "react-bootstrap/Modal";
import intl from "../../locale/en-US.json";
const SuperResponderModal = ({ closeSuperResponderModal}) => {
	return (
		<div
			className='modal show'
			style={{ display: "block", position: "initial" }}
		>
			<Modal show={true} onHide={closeSuperResponderModal}>
				<Modal.Header >
					<Modal.Title>{intl.assignedStudents}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h4>{intl.superResponderAssignMessage}</h4>
				</Modal.Body>
				<Modal.Footer>
					<button
						type='button'
						className='btn ActionButton PersonBtnadd '
						data-bs-dismiss='modal'
						onClick={closeSuperResponderModal}
					>
						{intl.okButton}
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default SuperResponderModal;
