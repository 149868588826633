import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Busy from '../../assets/notification_icons/call/busy.svg';
import CallSending from '../../assets/notification_icons/call/send.svg';
import Completed from '../../assets/notification_icons/call/completed.svg';
import NoAnswer from '../../assets/notification_icons/call/no_answer.svg';

import EmailDelivered from '../../assets/notification_icons/email/Email-Delivered.svg';
import EmailNotDelivered from '../../assets/notification_icons/email/Email-NotDelivered.svg';
import EmailSending from '../../assets/notification_icons/email/Email-Sending.svg';

import PhoneDelivered from '../../assets/notification_icons/phone/Phone-Delivered.svg';
import PhoneNotDelivered from '../../assets/notification_icons/phone/Phone-NotDelivered.svg';
import PhoneSending from '../../assets/notification_icons/phone/Phone-Sending.svg';

import Unverified from '../../assets/notification_icons/unverified.svg';
import SendNotificaitionOff from '../../assets/notification_icons/send_notification_off.svg';
import ReceiveNotificaitionOff from '../../assets/notification_icons/receive_notification_off.svg';

const getIcon = (type, status) => {
	status = typeof status === "string" ? status.toLowerCase() : "0";
	const callStatus = {
		sent: CallSending,
		completed: Completed,
		verified: Completed,
		busy: Busy,
		'no-answer': NoAnswer,
		failed: NoAnswer,
		unverified: Unverified,
		'receive notification off': ReceiveNotificaitionOff,
		'send notification off': SendNotificaitionOff,
	};
	const emailStatus = {
		sent: EmailSending,
		verified: EmailSending,
		delivered: EmailDelivered,
		not_delivered: EmailNotDelivered,
		failed: EmailNotDelivered,
		bounced: EmailNotDelivered,
		dropped: EmailNotDelivered,
		spam_report: EmailNotDelivered,
		invalid: EmailNotDelivered,
		unverified: Unverified,
		'receive notification off': ReceiveNotificaitionOff,
		'send notification off': SendNotificaitionOff,
	};
	const textStatus = {
		sent: PhoneSending,
		verified: PhoneSending,
		delivered: PhoneDelivered,
		undelivered: PhoneNotDelivered,
		failed: PhoneNotDelivered,
		unverified: Unverified,
		'receive notification off': ReceiveNotificaitionOff,
		'send notification off': SendNotificaitionOff,
	};

	if (type === 'call') {
		return callStatus[status];
	} else if (type === 'email') {
		return emailStatus[status];
	} else if (type === 'text') {
		return textStatus[status];
	}
};

export const NotificationIconTooltip = ({ recipient }) => {
	return (
		<>
			<OverlayTrigger
				placement='right'
				trigger={['focus', 'hover']}
				overlay={
					<Tooltip id='contact_tooltip'>
						<div className='p-2 text-start'>
							<h6 style={{ color: 'black', marginTop: '5px' }}>
								{recipient.email.status &&
									recipient.email.status.replace(/[_-]/g, '').toUpperCase()}
							</h6>
							<h6>{`${recipient.email.address} `}</h6>
						</div>
					</Tooltip>
				}
			>
				<img
					src={getIcon('email', recipient.email.status)}
					alt='Email  icon'
					width={20}
					height={20}
					style={{ marginLeft: '14px', cursor: 'pointer' }}
				/>
			</OverlayTrigger>
			<OverlayTrigger
				placement='right'
				trigger={['focus', 'hover']}
				overlay={
					<Tooltip id='contact_tooltip'>
						<div className='p-2 text-start'>
							<h6 style={{ color: 'black', marginTop: '5px' }}>
								{recipient.phone.status &&
									recipient.phone.status.replace(/[_-]/g, '').toUpperCase()}
							</h6>
							<h6>{`${recipient.phone.no} `}</h6>
						</div>
					</Tooltip>
				}
			>
				<img
					src={getIcon('text', recipient.phone.status)}
					alt='Text icon'
					width={20}
					height={20}
					style={{ marginLeft: '14px', cursor: 'pointer' }}
				/>
			</OverlayTrigger>
			{recipient.voice && (
				<OverlayTrigger
					placement='right'
					trigger={['focus', 'hover']}
					overlay={
						<Tooltip id='contact_tooltip'>
							<div className='p-2 text-start'>
								<h6 style={{ color: 'black', marginTop: '5px' }}>
									{recipient.voice.status &&
										recipient.voice.status.replace(/[_-]/g, '').toUpperCase()}
								</h6>
								<h6>{`${recipient.phone.no} `}</h6>
							</div>
						</Tooltip>
					}
				>
					<img
						src={getIcon('call', recipient.voice.status)}
						alt='Call icon'
						width={20}
						height={20}
						style={{ marginLeft: '14px', cursor: 'pointer' }}
					/>
				</OverlayTrigger>
			)}
		</>
	);
};

export const NotificationPopup = ({ recipient }) => {
	return (
		<div
			className='d-flex  align-items-center pr-2'
			style={{ paddingTop: '9px', paddingBottom: '9px' }}
		>
			<h6
				className='long_recipient d-flex justify-content-start' title={recipient.name}
				style={{ paddingRight: '5px', marginBottom: 0, paddingTop:'5px', paddingBottom:'5px', paddingLeft: '10px'}}
			>
				{recipient.name}
			</h6>
			<OverlayTrigger
				placement='right'
				trigger={['focus', 'hover']}
				overlay={
					<Tooltip id='contact_tooltip'>
						<div className='p-2 text-start'>
							<h6 style={{ color: 'black', marginTop: '5px' }}>
								{recipient.email.status &&
									recipient.email.status.replace(/[_-]/g, '').toUpperCase()}
							</h6>
							<h6>{`${recipient.email.address} `}</h6>
						</div>
					</Tooltip>
				}
			>
				<img
					src={getIcon('email', recipient.email.status)}
					alt='Email  icon'
					width={20}
					height={20}
					style={{ marginLeft: '18px', cursor: 'pointer' }}
				/>
			</OverlayTrigger>
			<OverlayTrigger
				placement='right'
				trigger={['focus', 'hover']}
				overlay={
					<Tooltip id='contact_tooltip'>
						<div className='p-2 text-start'>
							<h6 style={{ color: 'black', marginTop: '5px' }}>
								{recipient.phone.status &&
									recipient.phone.status.replace(/[_-]/g, '').toUpperCase()}
							</h6>
							<h6>{`${recipient.phone.no} `}</h6>
						</div>
					</Tooltip>
				}
			>
				<img
					src={getIcon('text', recipient.phone.status)}
					alt='Phone icon'
					width={20}
					height={20}
					style={{ marginRight: !recipient.voice && '15px', marginLeft: '18px', cursor: 'pointer'} }
				/>
			</OverlayTrigger>
			{recipient.voice && (
				<OverlayTrigger
					placement='right'
					trigger={['focus', 'hover']}
					overlay={
						<Tooltip id='contact_tooltip'>
							<div className='p-2 text-start'>
								<h6 style={{ color: 'black', marginTop: '5px' }}>
									{recipient.voice.status &&
										recipient.voice.status.replace(/[_-]/g, '').toUpperCase()}
								</h6>
								<h6>{`${recipient.phone.no} `}</h6>
							</div>
						</Tooltip>
					}
				>
					<img
						src={getIcon('call', recipient.voice.status)}
						alt='Call icon'
						width={20}
						height={20}
						style={{ marginLeft: '18px', cursor: 'pointer', marginRight:'15px'}}
					/>
				</OverlayTrigger>
			)}
		</div>
	);
};