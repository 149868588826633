import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { apiInstance, baseurl } from '../../api';
import intl from '../../locale/en-US.json';
import { Spinner } from 'react-bootstrap';
import { Popup } from 'semantic-ui-react';
import devicesImg from '../headsidebar/assets/images/sidebar/devices.svg';
import staffImg from '../headsidebar/assets/images/sidebar/staff.svg';
import alertsImg from '../headsidebar/assets/images/sidebar/alerts.svg';
import badgesImg from '../headsidebar/assets/images/sidebar/badges.svg';
import studentsImg from '../headsidebar/assets/images/sidebar/student.svg';
import schoolImage from '../headsidebar/assets/images/sidebar/school.svg';
import moment from 'moment';
import { formatDistanceToNow } from 'date-fns';
import {
	getRoleInStore,
	deleteEtag,
	getListInStore,
	getEtag,
	setEtag,
	setListInStore,
	HOME_ETAG_KEY,
	HOME_LIST_KEY,
	// setPriorityVerificationStatus,
} from '../../utils/apiUtils';
import { errorFunction } from '../../utils/errors';
import LogoutModal from '../Modal/LogoutModal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FaInfoCircle } from 'react-icons/fa';
import { Categories, logEvent, Actions } from '../../analytics';
import {
	startPolling,
	stopPolling,
	handleVisibilityChange,
} from '../../utils/polling';
import AdminInfo from './AdminInfo';

const Home = ({ history }) => {
	const [staff, setStaff] = useState({ active: 0, allowed: 0 });
	const [students, setStudents] = useState({
		active: 0,
		assigned: 0,
		allowed: 0,
	});
	const [badges, setBadges] = useState({
		assigned: 0,
		active: 0,
		allowed: 0,
	});
	const [devices, setDevices] = useState({
		panel: 0,
		gateway: 0,
		allowed: 0,
		active: 0,
	});
	const [alerts, setAlerts] = useState(0);
	const [school, setSchool] = useState({
		logo: '',
		name: '',
		isBlocked: '',
		isExpired: '',
		expiryDateFuzzy: '',
		expiryDate: '',
		timezone: '',
		licenseType: '',
	});
	const [loading, setLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const [imageURL, setImageURL] = useState('');
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const [showModal2, setShowModal2] = useState(false);
	const [alertList, setAlertList] = useState([]);
	const [adminInfo, setAdminInfo] = useState([]);
	const cardRef = useRef(null);
	const timeoutIdRef = React.useRef(null);
	const whiteImageSVG =
		'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="120" height="120"%3E%3Crect width="100%" height="100%" fill="%23fff" /%3E%3C/svg%3E';
	let isMounted = false;
	let isUnloaded = false;
	const pollingIntervalRef = React.useRef(null);
	const handleCardClick = (redirectTo) => {
		history.push(redirectTo);
	};

	const setAndParseReceivedObj = (dashboard, flag) => {
		if (dashboard) {
			if (dashboard.admin_information) {
				// const contactData = dashboard.admin_information.find(
				// 	(obj) => obj.role === 'PriorityContact'
				// );

				// if priority is added and is verified
				// if (
				// 	!flag && contactData &&
				// 	contactData.email_verification_code === null &&
				// 	contactData.phone_verification_code === null &&
				// 	contactData.email_verification_expiry === null &&
				// 	contactData.phone_verification_expiry === null
				// ) {
				// 	setPriorityVerificationStatus(true);
				// }
				
				setAdminInfo([...dashboard.admin_information]);

			}
		}

		if (dashboard) {
			if (
				dashboard.teachers &&
				dashboard.teachers.total_active !== undefined &&
				dashboard.teachers.allowed_teachers !== undefined
			) {
				setStaff({
					active: dashboard.teachers.total_active,
					allowed: dashboard.teachers.allowed_teachers,
					super_responders: dashboard.teachers.total_super_responders,
				});
			}
			if (
				dashboard.badges &&
				dashboard.badges.assigned_count !== undefined &&
				dashboard.badges.total_active !== undefined &&
				dashboard.badges.allowed_badges !== undefined
			) {
				setBadges({
					assigned: dashboard.badges.assigned_count,
					active: dashboard.badges.total_active,
					allowed: dashboard.badges.allowed_badges,
				});
			}
			if (
				dashboard.students &&
				dashboard.students.assigned_count !== undefined &&
				dashboard.students.total_active !== undefined &&
				dashboard.students.allowed_students !== undefined
			) {
				setStudents({
					active: dashboard.students.total_active,
					assigned: dashboard.students.assigned_count,
					allowed: dashboard.students.allowed_students,
				});
			}
			if (
				dashboard.devices &&
				dashboard.devices.panel_count !== undefined &&
				dashboard.devices.gateway_count !== undefined &&
				dashboard.devices.total_active !== undefined &&
				dashboard.devices.allowed_devices !== undefined
			) {
				setDevices({
					panel: dashboard.devices.panel_count,
					gateway: dashboard.devices.gateway_count,
					active: dashboard.devices.total_active,
					allowed: dashboard.devices.allowed_devices,
				});
			}
			if (
				dashboard.school &&
				dashboard.school.logo_file !== undefined &&
				dashboard.school.is_blocked !== undefined &&
				dashboard.school.expiry_date !== undefined
			) {
				const timestamp = moment(dashboard.school.expiry_date).valueOf();
				const fuzzyTime = formatDistanceToNow(new Date(timestamp), {
					addSuffix: true,
				});
				const expTime = moment(dashboard.school.expiry_date)
					.tz(timezone)
					.format('YYYY-MM-DD HH:mm:ss');

				// console.log(fuzzyTime, expTime);
				setSchool({
					logo: dashboard.school.logo_file,
					isBlocked: dashboard.school.is_blocked,
					licenseType: dashboard.school.license_type,
					expiryDateFuzzy: fuzzyTime,
					expiryDate: expTime,
					name: dashboard.school.name,
					timezone: dashboard.school.timezone,
				});
				setImageURL(baseurl + '/' + dashboard.school.logo_file);
			}

			if (dashboard.alerts && dashboard.alerts.count) {
				setAlerts(dashboard.alerts.count);
			}

			if (dashboard.alerts && dashboard.alerts.latest_alerts) {
				setAlertList(dashboard.alerts.latest_alerts);
			}
		}
	};

	const fetchData = async () => {
		const lowercasePathname = window.location.pathname.toLowerCase();
		if (!lowercasePathname.includes('dash')) {
			stopPolling(pollingIntervalRef);
			return;
		}
		setErrorMessage('');
		try {
			const url = '/api/v1/dashboards/school-main';
			const headers = {};
			const storedEtag = getEtag(HOME_ETAG_KEY);
			if (storedEtag) {
				headers['If-None-Match'] = storedEtag;
			}
			stopPolling(pollingIntervalRef);
			const res = await apiInstance.get(url, {
				headers,
			});
			if (!isUnloaded) {
				if (res && res.data && res.data.dashboard) {
					setAndParseReceivedObj(res.data.dashboard, false);
					setEtag(HOME_ETAG_KEY, res.headers.etag);
					setListInStore(HOME_LIST_KEY, JSON.stringify(res.data.dashboard));
				}
			}
			isMounted = true;
		} catch (error) {
			if (error.response && error.response.status === 304) {
				setErrorMessage('');
				return;
			}
			const result = errorFunction(error);
			console.log('result-------- ', result);
			if (typeof result === 'boolean') {
				setShowModal2(true);
			} else {
				setErrorMessage(result);
			}

			if (error.response && error.response.status === 304) {
				setErrorMessage('');
				return;
			}
		} finally {
			startPolling(fetchData, pollingIntervalRef);
			setLoading(false);
		}
	};

	useEffect(() => {
		const role = getRoleInStore();
		if (role === 'SuperAdmin') {
			history.push('/organizations');
		} else {
			const handlePageLoad = async () => {
				deleteEtag(HOME_ETAG_KEY);
				const dashboardData = getListInStore(HOME_LIST_KEY);
				if (dashboardData) {
					setAndParseReceivedObj(JSON.parse(dashboardData), true);
					setLoading(false);
				}
			};
			if (!isMounted) {
				handlePageLoad();
			}
			window.onbeforeunload = () => {
				deleteEtag(HOME_ETAG_KEY);
			};

			fetchData();

			// const handleVisibilityChange = () => {
			// 	if (document.visibilityState === 'visible') {
			// 		if (timeoutIdRef.current) {
			// 			clearTimeout(timeoutIdRef.current);
			// 		}
			// 		startPolling(fetchData, pollingIntervalRef);
			// 	} else {
			// 		stopPolling(pollingIntervalRef);
			// 	}
			// };
		}
		return () => {
			setLoading(false);
		};
	}, []);

	const visibilityChangeHandler = () =>
		handleVisibilityChange(timeoutIdRef, fetchData, pollingIntervalRef);

	useEffect(() => {
		// visibilityChangeHandler()

		document.addEventListener('visibilitychange', visibilityChangeHandler);

		return () => {
			stopPolling(pollingIntervalRef);

			isUnloaded = true;
			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
			}
			document.removeEventListener('visibilitychange', visibilityChangeHandler);
		};
	}, []);

	useEffect(() => {
		if (!loading) {
			const cardWrapperDivs = Array.from(
				cardRef.current.querySelectorAll('.card-wrapper')
			);
			const maxHeight = Math.max(
				...cardWrapperDivs.map((div) => div.offsetHeight + 5)
			);
			cardWrapperDivs.forEach((div) => {
				div.style.height = `${maxHeight}px`;
			});
		}
	}, [loading]);

	return (
		<div>
			{/* <div className='card-header card_headertitle d-flex mt-3'>
				<div className='card-title card_head'>
					<h4 className='card_head  CardTitle text-uppercase lg-font  '>
						{intl.organizationDetails}
					</h4>
				</div>
			</div> */}

			<div>
				{errorMessage && (
					<div className='col-lg-12 col-md-12 col-sm-12 col-12 btn_error'>
						{errorMessage}
					</div>
				)}
			</div>

			{loading ? (
				<div>
					<div className='d-flex justify-content-center align-items-center'>
						<Spinner animation='border' variant='secondary' />
					</div>
				</div>
			) : (
				<>
					<div
						className='card-header card_headertitle  mt-3 hover-pointer'
						onClick={() => {
							handleCardClick('/organizationSettings');
							logEvent({
								category: Categories.school,
								action: Actions.schoolSettings_btn,
								label: 'dashboard_card',
							});
						}}
					>
						<div className='row'>
							<div className='col-lg-2 col-md-4 col-sm-4 d-flex justify-content-center align-items-center logo-margin'>
								<div className='school_logo'>
									<center>
										<LazyLoadImage
											src={imageURL || schoolImage}
											style={
												!imageURL ? { width: '120px', height: '120px' } : {}
											}
											className='img-logo'
											alt='Organization Logo'
											onError={(e) => {
												e.target.src = whiteImageSVG;
												e.target.style.width = '120px';
												e.target.style.height = '120px';
											}}
											effect='blur'
										/>
									</center>
								</div>
							</div>

							<div className='d-flex flex-column col-lg-10 col-md-8 col-sm-8 logo-margin'>
								<div className='mb-4'>
									<h4 className='card_head  CardTitle text-uppercase text-center-school text-center'>
										<strong className='ml-2 text-center'>{school.name}</strong>
									</h4>
								</div>
								<div className='row d-flex'>
									{/* <div className='col-lg-1'></div> */}
									<div className='col-lg-4 col-md-4 mb-4 col-sm-12   text-center'>
										<p className='card_head  CardTitle text-uppercase  '>
											{intl.homePlan}
										</p>

										<p className='text-center card_head  CardTitle text-uppercase long_timezone '>
											<strong className='word-break' title={school.licenseType}>
												<strong>{school.licenseType}</strong>
											</strong>
										</p>
									</div>

									<div className='col-lg-4 col-md-4 mb-4 col-sm-12 ml-5-dashboard text-center'>
										<p className='card_head  CardTitle text-uppercase  '>
											{intl.expires}:
										</p>
										<p className='card_head  CardTitle text-uppercase   d-flex justify-content-center align-items-center'>
											<strong> {school.expiryDateFuzzy}</strong>
											<Popup
												position='top center'
												trigger={
													<FaInfoCircle
														onClick={(e) => e.stopPropagation()}
														style={{
															fontSize: '18px',
															marginLeft: '0.5em',
															color: '#66398E ',
														}}
														className=''
													/>
												}
											>
												{school.expiryDate}
											</Popup>
										</p>
									</div>

									{/* <div className='col-lg-2 col-md-3 mb-4 col-sm-12 text-center'>
										<p className='card_head  CardTitle text-uppercase  '>
											{intl.license}
										</p>

										<p className='card_head  CardTitle text-uppercase  '>
											<strong>
												{school.isBlocked ? intl.nonActive : intl.active}
											</strong>
										</p>
									</div> */}

									<div className='col-lg-4 col-md-4 mb-4 col-sm-12 text-center '>
										<p className='card_head  CardTitle text-uppercase  '>
											{intl.timezone}
										</p>

										<p className='card_head  CardTitle text-uppercase  long_timezone '>
											<strong className='word-break'>
												<strong
													title={school.timezone ? school.timezone : 'UTC'}
												>
													{school.timezone ? school.timezone : 'UTC'}
												</strong>
											</strong>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					{(adminInfo && adminInfo.length > 0) && <AdminInfo history={history} adminInfo={adminInfo} />}
					<div className='card-body blocked-class'>
						<div className='row justify-content-center' ref={cardRef}>
							<div className='col-lg-3 col-md-6 col-sm-6 col-12'>
								<div
									className='card p-3 hover-pointer card-with-border card-wrapper'
									style={{ minWidth: '11em' }}
									onClick={() => {
										logEvent({
											category: Categories.staff,
											action: Actions.staff_btn,
											label: 'dashboard_card',
										});
										handleCardClick(`/responder`);
									}}
								>
									<div>
										<h3
											className='dashboard-text-with-icon'
											style={{ gap: '5px' }}
										>
											<img
												src={staffImg}
												style={{ height: '24px', width: '24px' }}
												alt='Staff Icon'
											/>
											{intl.staffs}
										</h3>
									</div>
									<table className=''>
										<tbody>
											<tr>
												<td
													style={{
														width: '1%',
														minWidth: '1px',
														paddingRight: 0,
													}}
													className='pr-2'
												>
													{intl.allowed}:
												</td>
												<td align='left'>
													<strong>{staff.allowed}</strong>
												</td>
											</tr>
											<tr>
												<td
													style={{
														width: '1%',
														minWidth: '1px',
														paddingRight: 0,
													}}
													className='pr-2'
												>
													{intl.used}:
												</td>
												<td align='left'>
													<strong>{staff.active}</strong>
												</td>
											</tr>
											<tr>
												<td
													style={{
														width: '1%',
														minWidth: '1px',
														paddingRight: 0,
													}}
													className='pr-2'
												>
													{intl.super}:
												</td>
												<td align='left'>
													<strong>{staff.super_responders || 0}</strong>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div className='col-lg-3 col-md-6 col-sm-6 col-12'>
								<div
									className='card p-3 hover-pointer card-with-border card-wrapper'
									style={{ minWidth: '11em' }}
									onClick={() => {
										handleCardClick('/users');
										logEvent({
											category: Categories.student,
											action: Actions.student_btn,
											label: 'dashboard_card',
										});
									}}
								>
									<div>
										<h3
											className='dashboard-text-with-icon'
											style={{ gap: '5px' }}
										>
											<img
												src={studentsImg}
												style={{ height: '24px' }}
												alt='icon'
											/>
											{intl.students}
										</h3>
									</div>
									<table className=''>
										<tbody>
											<tr>
												<td
													style={{
														width: '1%',
														minWidth: '1px',
														paddingRight: 0,
													}}
													className='pr-2'
												>
													{intl.allowed}:
												</td>
												<td align='left'>
													<strong>{students.allowed}</strong>
												</td>
											</tr>
											<tr>
												<td
													style={{
														width: '1%',
														minWidth: '1px',
														paddingRight: 0,
													}}
													className='pr-2'
												>
													{intl.used}:
												</td>
												<td align='left'>
													<strong>{students.active}</strong>
												</td>
											</tr>
											<tr>
												<td
													style={{
														width: '1%',
														minWidth: '1px',
														paddingRight: 0,
													}}
													className='pr-2'
												>
													{intl.assigned}:
												</td>
												<td align='left'>
													<strong>{students.assigned}</strong>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div className='col-lg-3 col-md-6 col-sm-6 col-12'>
								<div
									className='card p-3 hover-pointer card-with-border card-wrapper'
									style={{ minWidth: '11em' }}
									onClick={() => {
										handleCardClick('/badge');
										logEvent({
											category: Categories.badge,
											action: Actions.badge_btn,
											label: 'dashboard_card',
										});
									}}
								>
									<div>
										<h3
											className='dashboard-text-with-icon '
											style={{ gap: '5px' }}
										>
											<img
												src={badgesImg}
												style={{ height: '24px' }}
												alt='icon'
											/>
											{intl.badges}
										</h3>
									</div>
									<table className=''>
										<tbody>
											<tr>
												<td
													style={{
														width: '1%',
														minWidth: '1px',
														paddingRight: 0,
													}}
													className='pr-2'
												>
													{intl.allowed}:
												</td>
												<td align='left'>
													<strong>{badges.allowed}</strong>
												</td>
											</tr>
											<tr>
												<td
													style={{
														width: '1%',
														minWidth: '1px',
														paddingRight: 0,
													}}
													className='pr-2'
												>
													{intl.used}:
												</td>
												<td align='left'>
													<strong>{badges.active}</strong>
												</td>
											</tr>
											<tr>
												<td
													style={{
														width: '1%',
														minWidth: '1px',
														paddingRight: 0,
													}}
													className='pr-2'
												>
													{intl.assigned}:
												</td>
												<td align='left'>
													<strong>{badges.assigned}</strong>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div className='col-lg-3 col-md-6 col-sm-6 col-12'>
								<div
									className='card p-3 hover-pointer card-with-border card-wrapper'
									style={{ minWidth: '11em' }}
									onClick={() => {
										handleCardClick('/device');
										logEvent({
											category: Categories.device,
											action: Actions.device_btn,
											label: 'dashboard_card',
										});
									}}
								>
									<div>
										<h3
											className='dashboard-text-with-icon '
											style={{ gap: '5px' }}
										>
											<img
												src={devicesImg}
												style={{ height: '24px' }}
												alt='icon'
											/>

											{intl.devices}
										</h3>
									</div>
									<table className=''>
										<tbody>
											<tr>
												<td
													style={{
														width: '1%',
														minWidth: '1px',
														paddingRight: 0,
													}}
													className='pr-2'
												>
													{intl.allowed}:
												</td>
												<td align='left'>
													<strong>{devices.allowed}</strong>
												</td>
											</tr>
											<tr>
												<td
													style={{
														width: '1%',
														minWidth: '1px',
														paddingRight: 0,
													}}
													className='pr-2'
												>
													{intl.used}:
												</td>
												<td align='left'>
													<strong>{devices.active}</strong>
												</td>
											</tr>
											<tr>
												<td
													style={{
														width: '1%',
														minWidth: '1px',
														paddingRight: 0,
													}}
													className='pr-2'
												>
													{intl.panelHome}:
												</td>
												<td align='left' className='word-break'>
													<strong>{devices.panel}</strong>
												</td>
												<td
													style={{
														width: '1%',
														minWidth: '1px',
														paddingRight: 0,
													}}
													className='pr-2'
												>
													{intl.gatewayHome}:
												</td>
												<td align='left' className='word-break'>
													<strong>{devices.gateway}</strong>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div
								className={
									alertList.length > 0
										? 'col-lg-6 col-12'
										: 'col-lg-3 col-md-6 col-sm-6 col-12'
								}
							>
								<div
									className={`${
										alertList.length === 0 && 'card-wrapper'
									} card hover-pointer card-with-border p-4`}
									style={{ minWidth: '11em', minHeight: '14em' }}
									onClick={() => {
										handleCardClick('/panelnotifications');
										logEvent({
											category: Categories.alert,
											action: Actions.alert_btn,
											label: 'dashboard_card',
										});
									}}
								>
									<div>
										<h3
											className='dashboard-text-with-icon '
											style={{ gap: '5px' }}
										>
											<img
												src={alertsImg}
												style={{ height: '24px' }}
												alt='icon'
											/>
											{intl.alerts}
										</h3>
									</div>
									<table className=''>
										<tbody>
											<tr>
												<td
													style={{
														width: '1%',
														minWidth: '1px',
														paddingRight: 0,
													}}
													className='pr-2'
												>
													{intl.totalCount}:
												</td>
												<td align='left'>
													<strong>{alerts}</strong>
												</td>
											</tr>
										</tbody>
									</table>

									{alertList.length > 0 && (
										<p style={{ marginTop: '6px', marginBottom: '3px' }}>
											<strong>Last 5 notifications</strong>
										</p>
									)}

									<table>
										<tbody>
											{alertList.map((alert, index) => {
												return (
													<tr key={index}>
														<td
															className='pl-2 long_timezone'
															style={{
																maxWidth: '110px',
																verticalAlign: 'top',
															}}
															title={alert.owner_name}
														>
															{alert.owner_name}
														</td>
														<td align='center' style={{ verticalAlign: 'top' }}>
															{moment(alert.timestamp)
																.tz(timezone)
																.format('DD MMM YYYY, HH:mm:ss')}
														</td>
														<td
															align='center'
															className='pl-3'
															style={{ verticalAlign: 'top' }}
														>
															{alert.device_type}
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			{showModal2 ? <LogoutModal /> : null}
		</div>
	);
};

export default withRouter(Home);
